import TimeTaxSupportSystemClient from '../../../system';
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  Facepile,
  IColumn,
  IDetailsListProps,
  IFacepilePersona,
  Icon,
  Persona,
  PersonaSize,
  SelectionMode,
  Text,
} from '@fluentui/react';
import React from 'react';
import { DateCustom } from '../../../lib/DateCustom';
import { ActivityJson } from '../../../system/activity/ActivitySystem';
import { parseDateTimeForList } from '../../../lib/Util';
import { MassiveDistributionCorrector } from '../../MassiveDistributionCorrector/MassiveDistributionCorrector';
import MessageCourier from '../../../lib/MessageCourier';
import { User } from '../../../system/User';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from '../../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import ProjectDescriptor from '../../../system/projects/ProjectDescriptor';

interface IActivitiesToEditByPeriodTable {
  system: TimeTaxSupportSystemClient;
  actividadesAEditar: ActivityJson[];
  courier: MessageCourier;
  user: User;
  changeBlockUI: (b: boolean) => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
  hideEdit?: boolean;
  projectId?: string;
  projects?: ProjectDescriptor[];
  handlePageChange?: (newPage: number) => void;
  handlePerPageChange?: (newPerPage: number) => void;
  page?:number;
  perPage?:number;
  totalRows?:number;
}

export const ActivitiesToEditByPeriodTable = (props: IActivitiesToEditByPeriodTable) => {
  return (
      <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
        <MassiveDistributionCorrector
            system={props.system}
            courier={props.courier}
            user={props.user}
            changeBlockUI={props.changeBlockUI}
            config={props.config}
            periodsBlocked={props.periodsBlocked}
            hideEdit={props.hideEdit}
            projectId={props.projectId}
            projects={props.projects}
            activitiesCreated={props.actividadesAEditar}
            handlePageChange={props.handlePageChange!}
            handlePerPageChange={props.handlePerPageChange!}
            page={props.page!}
            perPage={props.perPage!}
            totalRows={props.totalRows!}
        ></MassiveDistributionCorrector>
      </div>
  );
};
