import * as React from 'react';
import {
  Text,
  Icon,
  TextField,
  IComboBoxOption,
  IComboBox,
  IIconProps,
  IconButton,
  DefaultButton,
  Toggle,
  Checkbox,
  Label,
  Stack,
} from '@fluentui/react';
import { styles } from '../CalendarStyles';
import { Activity } from '../../../system/activity/Activity';
import { BillableDistribution } from '../../../system/billable_distribution/BillableDistribution';
import ProjectCompanySaved from '../../../system/projects/ProjectCompanySaved';
import TimeTaxSupportSystemClient from '../../../system';
import { ErrorIntermediate } from '../../Common/ErrorIntermediate';
import { ActivityValidations } from './ActivityValidations';
import { useTranslation, Trans } from 'react-i18next';
import useForceUpdate from '../../../common/custom-hooks/useForceUpdate';
import { ActionButton } from 'office-ui-fabric-react';
import ComboBoxSearch from '../../Common/ComboBoxSearch';
import { ProjectCompany } from '../../../system/projects/ProjectCompany';
import { Company } from '../../../system/company/Company';
import _lodash, { constant } from 'lodash';

interface IActivityDistribution {
  activity: Activity;
  edit: boolean;
  projectCompanies: ProjectCompanySaved[];
  system: TimeTaxSupportSystemClient;
  updateActivity?: () => void;
  userLoggedIsOwner: boolean;
  validations?: ActivityValidations;
  submitted?: boolean;
  projectContext?: any;
}

export const ActivityDistribution = (props: IActivityDistribution) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = React.useState<boolean>(true);
  const ChevronUpIcon: IIconProps = { iconName: 'ChevronUp' };
  const ChevronDownIcon: IIconProps = { iconName: 'ChevronDown' };
  const [isUniformDistribution, setIsUniformDistribution] = React.useState<boolean>(true);
  const [selectedDistributions, setSelectedDistributions] = React.useState<number[]>([]);
  const [total, setTotal] = React.useState<number>(100);

  const forceUpdate = useForceUpdate();

  const onChangeDistribution = (e: any, i: number) => {
    let totalsum = 0;
    if (props.activity.getProject()?.getCanEditDistribution()) {
      let value = e.target.value;
      if (!isNaN(+value) && (+value >= 0 || +value <= 100)) {
        let _dist = [...props.activity.getDistributionBillableList()];
        _dist[i].setPercentage(value);

        // Actualiza las distribuciones seleccionadas
        const selected = [...selectedDistributions];
        if (e.target.checked) {
          selected.push(i);
        } else {
          const index = selected.indexOf(i);
          if (index !== -1) {
            selected.splice(index, 1);
          }
        }
        setSelectedDistributions(selected);

        props.activity.setDistributionBillableList(_dist);
        if (props.updateActivity) props.updateActivity();
        _dist.forEach(dis=>{
          let value = parseFloat(dis.getPercentage()) || 0;
          totalsum += value;
        });
       setTotal(totalsum);
        forceUpdate();
      }
    }
  };

  const getActiveDistributions = () => {
    const distributionList = props.activity.getDistributionBillableList();
    const activeDistributions: BillableDistribution[] = [];

    distributionList.forEach((distribution, i) => {
      if (selectedDistributions.includes(i)) {
        activeDistributions.push(distribution);
      }
    });
    return activeDistributions;
  };

  const resetDistribution = () => {
    let distributionList = props.activity.getDistributionBillableList();

    distributionList.forEach((distribution) => {
      distribution.setPercentage('0');
    });

    setSelectedDistributions([]);
    setTotal(0);
    forceUpdate();
  };

  const toggleUniformDistribution = () => {
    // setSelectedDistributions([]);
    setIsUniformDistribution(!isUniformDistribution);
    if (isUniformDistribution) {
      let distributionList = props.activity.getDistributionBillableList();
      const selectedDistList = selectedDistributions.map((index) => distributionList[index]);
      const totalSelected = selectedDistList.length;
      if (totalSelected > 0) {
        const percentagePerDistribution = 100 / totalSelected;
        selectedDistList.forEach((distribution) => {
          distribution.setPercentage(percentagePerDistribution.toString());
        });
        forceUpdate();
      }
    }
  };

  const handleCheckboxChange = (e: any, i: number) => {
    const isChecked = e.target.checked;
    const selected = [...selectedDistributions];

    if (isChecked) {
      selected.push(i);
    } else {
      const index = selected.indexOf(i);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    }

    setSelectedDistributions(selected);

    const activeDistributions = selected.map((index) => props.activity.getDistributionBillableList()[index]);
    // Utiliza la lista de activeDistributions como necesites
  };

  const formatearNumero = (num: number): string => {
    return num % 1 === 0 ? num.toString() : num.toFixed(5).replace(/\.?0+$/, '');
  };

  React.useEffect(() => {
    if (isUniformDistribution) {
      const activeDistributions = getActiveDistributions();
      const totalActiveDistributions = activeDistributions.length;
      let sumTotal =  0;
      
      const allDistributions = props.activity.getDistributionBillableList();

      if (totalActiveDistributions > 0) {
        const percentagePerDistribution = 100 / totalActiveDistributions;

        allDistributions.forEach((distribution) => {
          if (activeDistributions.includes(distribution)) {
            distribution.setPercentage(percentagePerDistribution.toFixed(2));
          } else {
            distribution.setPercentage('0');
          }
          sumTotal += parseFloat(distribution.getPercentage());
        });
        setTotal(sumTotal);
        forceUpdate();
      }
    }
  }, [isUniformDistribution, selectedDistributions]);

  const companiesOutOfActivity = React.useMemo(() => {
    if (props.projectContext && props.projectContext.proyecto) {
      const companies = props.projectContext.proyecto.empresas.map((projectCompany: ProjectCompany) =>
          projectCompany.getCompany()
      ) as Company[];
      const companiesInActivity = props.activity.getDistributionBillableList().map((db) => db.getCompany());

      const ret = companies.filter((c) => !companiesInActivity.find((ca) => ca.isIdentifiedAs(c)));
      return ret;
    }
    return [];
  }, [props.projectContext, props.activity]);

  const addCompanyToDistributionBillable = (company: Company) => {
    const distributionBillableList = [...props.activity.getDistributionBillableList()];

    distributionBillableList.push(
        new BillableDistribution(props.system.getCompanySystem(), true, '', '0', company, false)
    );

    props.activity.setDistributionBillableList(distributionBillableList);
    if (props.updateActivity) props.updateActivity();
  };


  let tmpDistribEmpresas: BillableDistribution[] = _lodash.cloneDeep(props.activity.getDistributionBillableList());
  const [renderCount, setRenderCount] = React.useState(0);
  const [tmpNewDistribEmpresas, setTmpNewDistribEmpresas] = React.useState<BillableDistribution[]>([]);

  const onChangeCustomInput = (val: any) => {
    let newDistribEmpresas: BillableDistribution[] = [];
    let tmpNewDistribEmpresasForUpdate: BillableDistribution[] = _lodash.cloneDeep(tmpDistribEmpresas);
    let totalsum = 0;
    if (val) {
      setTmpNewDistribEmpresas(tmpDistribEmpresas);
      props?.projectContext?.proyecto?.empresas.forEach(async (empresa, index) => {
        
        let tmpBillableDistribution = new BillableDistribution(
          props.system.getCompanySystem(), true,'',
          empresa.projectCompany.percentage.toFixed(2).toString(), empresa.projectCompany.getCompany())
          newDistribEmpresas.push(tmpBillableDistribution)
          totalsum += parseInt(tmpBillableDistribution.getPercentage());
        });
        
        let tmpNewDistribEmpresasForUpdateArray: BillableDistribution[] = [];
      tmpNewDistribEmpresasForUpdate.forEach((billableDistribution, index) => {
        billableDistribution.__delete = true;
        billableDistribution.setPercentage('0')
        tmpNewDistribEmpresasForUpdateArray.push(billableDistribution);
      })
      newDistribEmpresas = tmpNewDistribEmpresasForUpdate.concat(newDistribEmpresas);
      props.activity.setDistributionBillableList(newDistribEmpresas);
    } else {
      tmpDistribEmpresas = tmpNewDistribEmpresas;
      props.activity.setDistributionBillableList(tmpDistribEmpresas);
      tmpDistribEmpresas.forEach(v=>{
        totalsum += parseInt(v.getPercentage());
      })
    }
    setTotal(totalsum);
    setRenderCount(renderCount => renderCount + 1);
  };

  return (
      <div>
        {props.activity.getProject()?.getCanViewDistribution() && (
            <div id="dist-section">
              {/* #########################################################################################################################
                                                  Se muestra cuando es editable
      ######################################################################################################################### */}
              {props.edit && (
                  <>
                    <Text block variant="medium" className={styles.subtitleCardDist}>
                      {t('Distribución facturable')}

                      <IconButton
                          className="float-right"
                          iconProps={!show ? ChevronDownIcon : ChevronUpIcon}
                          title={!show ? t('Ver distribucion facturable') : t('Ocultar distribucion facturable')}
                          ariaLabel=""
                          onClick={() => setShow(!show)}
                      />
                      {show && props.userLoggedIsOwner ? (
                          <ActionButton
                              style={{ position: 'relative', bottom: '11px', marginRight: '90px' }}
                              className="float-right"
                              onClick={resetDistribution}
                              text={t('Reset')}
                          />
                      ) : null}
                      {show && props.userLoggedIsOwner ? (
                          <Toggle
                              onText={t('Distribución uniforme')}
                              offText={t('Distribución manual')}
                              className="float-right"
                              checked={isUniformDistribution}
                              onChange={toggleUniformDistribution}
                          />
                      ) : null}
                    </Text>

                    {props.validations && (
                        <div className="ml-3">
                          <ErrorIntermediate
                              validation={props.validations.totalDistribution}
                              submitted={!!props.submitted}
                              params={props.activity.getDistributionBillableList()}
                          ></ErrorIntermediate>
                        </div>
                    )}

                    {show && (
                        <>
                          {!props.activity.isNew() && props.userLoggedIsOwner && (
                              <div>
                                <Label>Agregar empresa por fuera de la actividad</Label>
                                <ComboBoxSearch
                                    className={'width-95'}
                                    disabled={!props.userLoggedIsOwner}
                                    options={companiesOutOfActivity.map((company: Company) => {
                                      let ret: IComboBoxOption = {
                                        key: company.getId(),
                                        text: company.getName(),
                                      };
                                      return ret;
                                    })}
                                    selected={['-1']}
                                    onSelectOption={(o) =>
                                        addCompanyToDistributionBillable(
                                            companiesOutOfActivity.find((c) => c.isIdentifiedBy(String(o.key)))!
                                        )
                                    }
                                    cleanOnSelect={true}
                                ></ComboBoxSearch>
                                <Text variant="small">
                                  <Icon style={{position: 'relative', top: 2, marginRight: 2}} iconName="Info"></Icon>
                                  En el menu desplegable verás las empresas que están en el proyecto pero no en la
                                  actividad
                                </Text>
                              </div>
                          )}
                          <div style={{marginTop: '33px'}}>
                            <Checkbox
                                styles={{
                                  label: {

                                  },
                                }}
                                label={('Incorporar distribución de Proyecto')}
                                boxSide="end"
                                name={('check-accept-distribucion-proy')}
                                //checked={input.getValue()}
                                onChange={(ev, val) => {
                                  onChangeCustomInput(val);
                                }}
                            />
                          </div>     
                        {props.validations && (
                        <div className="ml-3">
                          <ErrorIntermediate
                              validation={props.validations.totalprocent}
                              submitted={!!props.validations}
                              params={props.activity.getDistributionBillableList() || []}
                          ></ErrorIntermediate>
                        </div>
                    )}
                          <div key={renderCount}>
                            <Text className={'ms-motion-fadeIn'}>
                              <div style={{marginTop:"10px", color:"red"}} > {total <= 99 && total < 100? "La asignacion total debe ser 100%":""} </div>
                              {props.activity.getDistributionBillableList() &&
                                  props.activity.getDistributionBillableList().map((disfa: BillableDistribution, i) => {
                                    // Add condition here to only process items where __delete is false.
                                    if (!disfa.__delete) {
                                      return (
                                          <div key={i}>
                                            <div className={styles.distEmpContainer}>
                                              <div className={styles.sectionTextDist}>
                                                <Icon iconName="CityNext2" className="text-icon "/>
                                                {disfa.getCompany().getName()}
                                              </div>
                                              <div className={styles.sectionInputDist}>
                                                <div className={'ms-Grid'} dir="ltr">
                                                  <div className={'ms-Grid-row'} style={{position: 'relative', top: '10px'}}>
                                                    <div className="ms-Grid-col ms-sm8">
                                                      <TextField
                                                          onChange={(e) => {
                                                            onChangeDistribution(e, i);
                                                          }}
                                                          label=""
                                                          disabled={!props.userLoggedIsOwner}
                                                          value={disfa.getPercentage()}
                                                          suffix="%"
                                                      />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm4">
                                                      <Checkbox
                                                          className="mt-1"
                                                          checked={selectedDistributions.includes(i)}
                                                          onChange={(e) => handleCheckboxChange(e, i)}
                                                          disabled={!props.userLoggedIsOwner}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {props.validations && (
                                                <div className="ml-3">
                                                  <ErrorIntermediate
                                                      validation={props.validations.individualDistribution}
                                                      submitted={!!props.submitted}
                                                      params={disfa}
                                                      ></ErrorIntermediate>
                                                </div>
                                            )}
                                          </div>
                                      );
                                    }
                                  })
                              }
                            <Stack tokens={{ childrenGap: 8 }} styles={{ root: { alignItems: 'flex-end', paddingInlineEnd:"80px", marginTop:"20px" } }}>
                              <Text variant="medium">Asignación</Text>
                              <Text variant="medium">total: {formatearNumero(total)}%</Text>
                            </Stack>
                            </Text>
                          </div>

                        </>
                    )}
                  </>
              )}
              {/* #########################################################################################################################
                                                  Se muestra cuando no es editable
      ######################################################################################################################### */}
              {!props.edit && (
                  <>
                    <Text block variant="medium" className={styles.subtitleCardDist}>
                      <Icon iconName="CityNext2" className="text-icon " />
                      {t('Distribución facturable')}
                      <DefaultButton className="float-right" onClick={resetDistribution} text={t('Reset')} />
                      <IconButton
                          className="float-right"
                          iconProps={!show ? ChevronDownIcon : ChevronUpIcon}
                          title={!show ? t('Ver distribucion facturable') : t('Ocultar distribucion facturable')}
                          ariaLabel=""
                          onClick={() => setShow(!show)}
                      />
                    </Text>
                    {show && (
                        <Text>
                          {props.activity.getDistributionBillableList() &&
                              props.activity.getDistributionBillableList().map((disfa: BillableDistribution, i) => {
                                return (
                                    <div className={styles.distEmpContainer} key={i}>
                                      <div className={styles.sectionTextDist}>{disfa.getCompany().getName()}</div>
                                      <div className={styles.sectionInputDist}>
                                        <TextField value={disfa.getPercentage()} readOnly={true} label="" suffix="%" />
                                      </div>
                                    </div>
                                );
                              })}
                        </Text>
                    )}
                  </>
              )}
            </div>
        )}
      </div>
  );
};
