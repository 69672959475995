import { useEffect, useState } from "react";
import { Checkbox, ComboBox, DatePicker, IColumn, IComboBoxOption, Icon, PrimaryButton, TooltipHost } from "@fluentui/react";
import CustomModal, { ModalConfirm } from "./CustomModal";
import CustomTable from "./CustomTable";
import { spanishDatePickerStrings } from "../collaboratorCreateView";
import { DateCustom } from "../../../../lib/DateCustom";
import { IUpdateInformacionGeneral } from "./InformacionGeneral";
import { useForm } from "react-hook-form";
import formatearFecha from "../../../../common/formatDate";
import { convertStringToDateProyectoEnalce } from "../../../../common/custom-hooks/TodayDate";
import { EstructuraOrganizacional, IEstructuraOrganizacional, IFormatEstructuraOrganizacional, IListtransformUpdate } from "./interface";
import Environment from "../../../../lib/Environment";



function EstructuraOrganizacionalNEW({system,datosColaborador,nodoNivel,selecusuariosG}:IUpdateInformacionGeneral) {
    const valueEdingManagement = new Environment().EditingManagement() || "1";
    const [activeModal,setActiveModal] = useState<boolean>(false);
    const [eliminar, setEliminar] = useState<boolean>(false);
    const [desactivar , setDesactivar] = useState<boolean>(false);
    const [modificarCrear,setModificarCrear] = useState<boolean>(true);
    const [valueTem,setValueTem] = useState<any>(undefined);
    const [loading,setLoading] = useState<boolean>(true);
    const {register,setValue,formState: { errors },watch,handleSubmit,reset,clearErrors} = useForm();

    const columns: IColumn[] = [
      { key: 'column7', name: 'Activo', fieldName: 'Activo', minWidth: 45, isResizable: true,
        onRender: (item) => (<div style={{display:"flex",alignItems:"center",justifyContent:"center" }}>
         <Checkbox disabled={datosColaborador?!datosColaborador.col_activo:true} key={"001"} checked={item.Activo === "1"?true:item.Activo==="0"?false:undefined} onChange={()=>{
           setValueTem(item)
           setDesactivar(!desactivar)
           setModificarCrear(true)
           cleanDateTotal() }} />
          </div> )
        },
        { key: 'column8', name: 'Eliminar', fieldName: 'Eliminar', minWidth: 60, isResizable: true,
           onRender: (value) => ( <div style={{display:"flex",alignItems:"center",justifyContent:"center" ,color:"red",cursor:"pointer"}}>
       {  item.length === 1 || (datosColaborador?!datosColaborador.col_activo:true)?  <Icon iconName='Delete' style={{fontSize:"20px", color:"black"}} /> : <Icon  style={{fontSize:"20px"}} iconName='Delete' onClick={()=>{
           setValueTem(value)
          setEliminar(!eliminar)
          setModificarCrear(true)
          cleanDateTotal() }}/>}</div>
        )
        },
        { key: 'column9', name: 'Modificar', fieldName: 'Modificar', minWidth: 65, isResizable: true ,
          onRender: (item) => ( <div style={{display:"flex",alignItems:"center",justifyContent:"center" ,color:"#0078D4",cursor:"pointer"}}>
{ (datosColaborador?datosColaborador.col_activo:true)? <Icon style={{fontSize:"20px"}} iconName='SingleColumnEdit' onClick={()=>{
            cleanDateTotal();
            setLoading(false);
            SetDataModificar(item)
            setModificarCrear(false);
            setTimeout(()=>{
              setLoading(true);
            },888)
          }}/>:
          <Icon  style={{fontSize:"20px",color:"black"}} iconName='SingleColumnEdit'/>
          }
          </div>
        )
        },
        { key: 'column5', name: 'Desde', fieldName: 'Desde', minWidth: 80, isResizable: true },
        { key: 'column6', name: 'Hasta', fieldName: 'Hasta', minWidth: 80, isResizable: true },
        { key: 'column1', name: 'Dirección', fieldName: 'Direccion', minWidth:100, isResizable: true },
        { key: 'column2', name: 'Gerencia', fieldName: 'Gerencia', minWidth: 100, isResizable: true },
        { key: 'column3', name: 'Jefatura', fieldName: 'Jefatura', minWidth: 100, isResizable: true},
        { key: 'column4', name: 'Cargo', fieldName: 'Cargo', minWidth: 100, isResizable: true },
        ]

    const [item,setItem] = useState<IFormatEstructuraOrganizacional[] >([]);
   
    const [direction,setDirection] = useState<IComboBoxOption[]>([]);
    const [directionFilter,setDirectionFilter] = useState<IComboBoxOption[]>([]);
    const [newDirection,setNewDirection] = useState<IListtransformUpdate>({key:"9999",text:""});

    const [gerencia,setGerencia] = useState<IComboBoxOption[]>([]);
    const [gerenciaFilter,setGerenciaFilter] = useState<IComboBoxOption[]>([{key:"0000000001",text:"selecione una dirección"}]);
    const [newGerencia,setNewGerencia] = useState<IListtransformUpdate>({key:"9999",text:""})

    const [jefatura,setJefatura] = useState<IComboBoxOption[]>([]); 
    const [jefaturaFilter,setJefaturaFilter] = useState<IComboBoxOption[]>([{key:"0000000001",text:"selecione una gerencia"}]); 
    const [newJefatura, setNewJefatura] = useState<IListtransformUpdate>({key:"9999",text:""});

    const [cargo, setCargo] = useState<IListtransformUpdate[]>([]);
    const [cargofilter, setCargofilter] = useState<IListtransformUpdate[]>([]);
    const [newcargo,setNewcargo] = useState<IListtransformUpdate>({key:"9999",text:""});

    const [selectedOptionGerencia, setSelectedOptionGerencia] = useState<any>(null);
    const [selectedOptionJefatura, setSelectedOptionJefatura] = useState<any>(null);

    const [valueCargo, setValueCargo] = useState<string | undefined>("");
    const [valueDireccion, setValueDireccion] = useState<string | undefined>("");
    const [valueGerencia, setValueGerencia] = useState<string | undefined>("");
    const [valueJefatura, setValueJefatura] = useState<string | undefined>("");
    const [valueDateDesde, setValueDateDesde] = useState<string | undefined>(undefined);
    const [valueDateHasta, setValueDateHasta] = useState<string | undefined>(undefined);

    const [minDateVigencia,setMinDateVigencia] = useState<Date | undefined>(undefined);

   const cleanDateInputs = ()=>{
    setValueGerencia(undefined);
    setValue("gerencia",undefined);
    setGerenciaFilter([]);
    setJefaturaFilter([]);
    if(selecusuariosG){
      if (selecusuariosG.text !== "Directivo"){
        setValueJefatura(undefined);
        setValue("jefatura",undefined);
      }
    }
   };
    
   const cleanDateTotal= ()=>{
    setValueJefatura(undefined);
    setValueCargo(undefined);
    setValueDireccion(undefined);
    setValueGerencia(undefined);
    setValueDateDesde(undefined);
    setValueDateHasta(undefined);
    setGerenciaFilter([]);
    setJefaturaFilter([]);
    setValue("direccion", undefined);
    setValue("gerencia", undefined);
    setValue("cargo",undefined);
    setValue("jefatura",undefined);
    setValue("carcol_fecha_desde",undefined);
    setValue("carcol_fecha_hasta",undefined);
   }

    const onselectDirección = async (option:any)=>{
      cleanDateInputs();
      let data:IListtransformUpdate | undefined = option;
        if(option === undefined && newDirection.text !== ""){
          data = newDirection
        }
      
       setValue("direccion",data);
       setValueDireccion(data?.text);
        if(option !== undefined){
          const response = await system!.getselcts("2",data?.key);
          const directions :IComboBoxOption[]= response.map((item)=>{
            const obejts ={
            key:item.nor_id,
            text:item.nor_nombre,
            nor_nivel:item.nor_nivel,
            nor_padre:item.nor_padre
            }
            return obejts
          });
          setGerencia(directions);
          setGerenciaFilter(directions); 
        }
    }

    const onselectGerencia =async (option:any) => {
      let data: IListtransformUpdate = option;
      if(option === undefined && newGerencia.text !== ""){
          data = newGerencia
      };
      setValue("gerencia",data);
      setValueGerencia(data.text);
      setSelectedOptionGerencia(option)
      setJefaturaFilter([]);
        if(option !== undefined){
          const response = await system!.getselcts("3",data.key);
          const directions :IComboBoxOption[]= response.map((item)=>{
          const obejts ={
            key:item.nor_id,
            text:item.nor_nombre,
            nor_nivel:item.nor_nivel,
            nor_padre:item.nor_padre,
          }
          return obejts
          });
          setJefatura(directions);
          setJefaturaFilter(directions);
        }
    }

    const onselectJefatura = (option) =>{
      let data:IListtransformUpdate = option;
      if(option === undefined && newJefatura.text !== ""){
        data = newJefatura
      }
      setValue("jefatura",data);
      setValueJefatura(data.text);
      setSelectedOptionJefatura(option)
    }
    
    const dataDirection = async()=>{
      if(!system)return ;
      try {
      const  response  = await system.getselcts();
      const directions :IComboBoxOption[]= response.map((item)=>{
      const obejts ={
       key:item.nor_id,
       text:item.nor_nombre,
       nor_nivel:item.nor_nivel,
       nor_padre:item.nor_padre
      }
      return obejts
     });
      setDirection(directions);
      setDirectionFilter(directions);
      } catch (error) {
        console.log(error)
      }
    }

    const cargoList = async ()=>{
      if(!system)return ;
      try {
         const response = await system.listCargo();
         const directions= response.map((item)=>{
          const obejts ={
           key:item.cargo_id,
           text:item.cargo_nombre,
          }
          return obejts
         });
         setCargo([...directions]);
         setCargofilter([...directions]);
      } catch (error) {
        console.log(error)
      }
    }

  const directionFilterChange = (text:string)=>{
      setNewDirection({...newDirection,text});
     let filtrado = direction.filter(d=> d.text.toLowerCase().includes(text.toLowerCase()));
     setDirectionFilter(filtrado);
  }

   const gerenciaFilterChange = (text:string)=>{
         setNewGerencia({...newGerencia,text});
       let filtrado = gerencia.filter(g=>g.text.toLowerCase().includes(text.toLowerCase()));
       setGerenciaFilter(filtrado);
   }

   const  filterFejaturaChange = (text:string)=>{
        setNewJefatura({...newJefatura, text});
       let filtrado = jefatura.filter(j=>j.text.toLowerCase().includes(text.toLowerCase()));
       setJefaturaFilter(filtrado);
   }

    const onInputValueChangeCargo = (text:string)=>{
            setNewcargo({...newcargo,text});
            let filtrado =  cargo.filter(c => c.text.toLowerCase().includes(text.toLowerCase()));
           setCargofilter(filtrado);
    }

   const onChangeCargoo = (event,option) => {
     let data = option;
     if(option === undefined && newcargo.text !== ""){
         data = newcargo;
     }
     setValue("cargo",data);
     setValueCargo(data?.text) 
   }

   const handlerVigencia = (date:Date | null | undefined) =>{
    setValueDateDesde(date?formatearFecha(date):undefined)
    setValue("carcol_fecha_desde",date?formatearFecha(date):undefined);
  }

  const handlerFechaFin = (date:Date | null | undefined)=>{
    setValueDateHasta(date?formatearFecha(date):undefined)
    setValue("carcol_fecha_hasta",date?formatearFecha(date):undefined);
  }

  const SetDataModificar = (item:IFormatEstructuraOrganizacional)=>{
    setValue("direccion", item.Direccion);
    setValue("gerencia",item.Gerencia);
    setValue("cargo", item.Cargo);
    setValue("jefatura",item.Jefatura);
    setValue("carcol_fecha_desde",item.Desde);
    setValue("carcol_fecha_hasta",item.Hasta);
    setValue("carcol_id",item.carcol_id);
    setValue("activo",item.Activo);
    setValueCargo(item.Cargo);
    setValueDateDesde(item.Desde);
    setValueDateHasta(item.Hasta);
    setValueDireccion(item.Direccion);
    setValueGerencia(item.Gerencia);
    setValueJefatura(item.Jefatura);
  }

  const dataTableItem = async ()=>{
      if(!datosColaborador?.cargoColaborador.carcol_colid)return ;
      const result:EstructuraOrganizacional[] = await system?.ListEstructuraOrganizacional(datosColaborador?.cargoColaborador.carcol_colid);
      if(result.length === 0)return;
      
      const mapResult = result.map(i=>{
        return{
          Direccion:i.nodo_organigrama.Nivel1.nor_nombre,
          Gerencia:i.nodo_organigrama.Nivel2.nor_nombre,
          Jefatura:i.nodo_organigrama.Nivel3.nor_nombre,
          Cargo:i.cargo.cargo_nombre,
          Desde:i.carcol_fecha_desde,
          Hasta:i.carcol_fecha_hasta,
          Activo:i.carcol_activo,
          carcol_id:i.carcol_id
        }
      })
      setItem(mapResult);
  }

  const delteItem = async (action:string)=>{
   if(!valueTem)return;
   if(action === "onConfirm"){
    setModificarCrear(true);
    const data = item?.filter(item =>item.carcol_id !== valueTem?.carcol_id);
    setItem(data);
     await system?.DeleteEstructuraOrganizacional(valueTem?.carcol_id);
     setValueTem(undefined);
     dataTableItem();
    }else if(action === "onclose"){
      setValueTem(undefined);
    }
    setEliminar(!eliminar);
  }

  const Update = async (valor:string)=>{
    if(!valueTem)return;
    if(valor === "onConfirm"){
       const desactivar = item.map(item =>{
          if(item.carcol_id === valueTem.carcol_id){
            if(item.Activo === "1"){
              item.Activo = "0";
              setValueTem({...valueTem,Activo: "0"});
            }else if(item.Activo === "0"){
               item.Activo = "1";
               setValueTem({...valueTem,Activo: "1"});
            }
          }
          return item;
       });
       setItem(desactivar);
       const parseDataUppdate = {
        carcol_id:valueTem.carcol_id,
        carcol_colid:datosColaborador?.cargoColaborador.carcol_colid,
        carcol_fecha_desde: valueTem.Desde,
        carcol_fecha_hasta: valueTem.Hasta,
        carcol_activo:valueTem.Activo,
        direccion: { key: "000", text: valueTem.Direccion },
        gerencia:{ key: "000", text: valueTem.Gerencia },
        jefatura:valueTem.Jefatura?{ key: "000", text: valueTem.Jefatura }:{ key: "000", text: "000" },
        cargo:{ key: "000", text: valueTem.Cargo }
      }
      await system?.UpdateEstructuraOranizacional(parseDataUppdate);
       setValueTem(undefined);
       dataTableItem();
    }else if(valor === "onclose"){
      setValueTem(undefined);
    }
    setDesactivar(!desactivar)
  } 

  const ParseDateUtis = (result:IEstructuraOrganizacional)=>{
    const parsed: IFormatEstructuraOrganizacional = {
      Activo: result?.carcol_activo || "1",
      Cargo: result.cargo?.text || "",
      Desde: result?.carcol_fecha_desde || "",
      Direccion: result?.direccion?.text || "",
      Gerencia: result?.gerencia?.text || "",
      Hasta: result?.carcol_fecha_hasta || "",
      Jefatura: result?.jefatura?.text || "",
      carcol_id:result?.carcol_id ||""
    };
    return parsed;
  }

  const addEstructuraOrganizaional = (result:IEstructuraOrganizacional)=>{
    const parsed: IFormatEstructuraOrganizacional = ParseDateUtis(result);
    setItem([parsed,...item]);
  }

  const updateOraganigrama = (result:IEstructuraOrganizacional)=>{
    const parsed: IFormatEstructuraOrganizacional = ParseDateUtis(result);
    const addUpdateitem= item.map(item => {
      if (item.carcol_id === parsed.carcol_id) {
        return parsed;
    }
    return item;
    });
    setItem(addUpdateitem);
  }

   const onsubmit = handleSubmit (async (data)=>{
    setLoading(false);
       let result = data as IEstructuraOrganizacional;
    const datosSave:IEstructuraOrganizacional={
      carcol_id:result.carcol_id,
      carcol_colid:datosColaborador?.cargoColaborador.carcol_colid,
      carcol_fecha_desde: result.carcol_fecha_desde,
      carcol_fecha_hasta: result.carcol_fecha_hasta,
      carcol_activo:"1",
      direccion:result.direccion,
      gerencia:result.gerencia,
      jefatura:result.jefatura || { key: "000", text: "000" },
      cargo:result.cargo
    };
    if(modificarCrear){
      await system?.CreateEstructuraOranizacional(datosSave);
      addEstructuraOrganizaional(result);
      cleanDateTotal();
      dataTableItem();
    }else{
      const parseDataUppdate = {
        carcol_id:data.carcol_id,
        carcol_colid:datosColaborador?.cargoColaborador.carcol_colid,
        carcol_fecha_desde: data.carcol_fecha_desde,
        carcol_fecha_hasta: data.carcol_fecha_hasta,
        carcol_activo:"1",
        direccion:data?.direccion?.text?data.direccion:{ key: "000", text: data.direccion || "000"},
        gerencia:data?.gerencia?.text?data.gerencia:{ key: "000", text: data.gerencia || "000"},
        jefatura:data?.jefatura?.text?data.jefatura:{ key: "000", text: data?.jefatura || "000"},
        cargo:data?.cargo?.text? data?.cargo: {key: "000", text: data?.cargo || "000"}
      }
      await system?.UpdateEstructuraOranizacional(parseDataUppdate);
      updateOraganigrama(parseDataUppdate);
      dataTableItem();
      cleanDateTotal();
      if(item.length){

        setModificarCrear(true);
      }
    }
    setTimeout(()=>{
      setLoading(true);
    },888)
   });


    useEffect(() => {
      register("direccion", { required: true });
      register("gerencia", { required: true });
      register("cargo", { required: true });
      register("jefatura", { required: true });
      register("carcol_fecha_desde", { required: true });
      register("carcol_fecha_hasta");
      register("carcol_id");
      register("activo");
      if(datosColaborador?.cargoColaborador.carcol_colid){
         dataTableItem();
      }
      if(activeModal === true) { 
          dataDirection();
          cargoList();
          cleanDateTotal();
          setModificarCrear(true);
      if (selecusuariosG) {
          if (selecusuariosG.text === "Directivo") {
            setValue("jefatura",{ key: "000", text: "000" })
            register("jefatura",{ required: false,value:{ key: "000", text: "000" } }); 
          } 
      }}
    }, [selecusuariosG,activeModal]);

    useEffect(()=>{
      if(item){
        if(modificarCrear === true){ 
          let convertStringToDat = item[0]?.Hasta;
          if(convertStringToDat) {
            setMinDateVigencia(convertStringToDateProyectoEnalce(convertStringToDat));
          }
        }else{
          clearErrors()
          setMinDateVigencia(undefined);
        }
      }
      
      register("carcol_fecha_desde",{required:true,validate:(e)=>{
        if(item?.length === 0)return true;
        if(modificarCrear === true){
          if(item[0].Hasta===null){
          return "error";
          }
          return true
        }else{
          return true;
        }
      }});
     
    },[modificarCrear,activeModal,item]);

    if(!datosColaborador?.col_activo){
      return(      
        <div className="form_informacion_general">
        <p className="titulo_form_create"> <Icon iconName="Org" style={{fontSize:"25px"}} /> Estructura Organizacional </p>
        <PrimaryButton style={{marginLeft:"15px"}} onClick={()=>setActiveModal(!activeModal)}> Organización </PrimaryButton>
        {activeModal &&
        <CustomModal hidden={activeModal} onDismiss={()=>setActiveModal(!activeModal)} key="Estructura Oraganizacional" 
           title='Detalles Estructura Organizacional' minWidth={700} minHeight={330} >
          <div  style={{  maxHeight: '220px', display: 'block', overflowY: 'auto',}} >
          <CustomTable items={item} columns={columns} />
          </div>
        </CustomModal>
        }
        </div>
        )
    }
    
    
  return (
    <div className="form_informacion_general">
      <p className="titulo_form_create"> <Icon iconName="Org" style={{fontSize:"25px"}} /> Estructura Organizacional </p>
      <PrimaryButton style={{marginLeft:"15px"}} onClick={()=>setActiveModal(!activeModal)}> Organización </PrimaryButton>
      {activeModal &&
      <CustomModal hidden={activeModal} onDismiss={()=>setActiveModal(!activeModal)} key="Estructura Oraganizacional" 
         title='Detalles Estructura Organizacional' minWidth={700} minHeight={650} >
        <div  style={{  maxHeight: '220px', display: 'block', overflowY: 'auto',}} >
        <CustomTable items={item} columns={columns} />
        </div>
        <hr /> 
        <div>
{     loading?       <form className="cuerpo_izquierdo_form" onSubmit={onsubmit}>
                <ComboBox
                allowFreeInput={true}
                className="label_reusable"
                placeholder="Seleccione la dirección"
                label="Dirección"
                options={directionFilter}
                styles={{ root: { width: "250px" } }}
                onChange={(_,option)=>onselectDirección(option) }
                onInputValueChange={directionFilterChange}
                text={valueDireccion}
                allowFreeform={valueEdingManagement === "1"? true:false}
                />
              { errors.direccion?.type === "required" && <span className="error_TextFile_proyectofijo" >La direccion no puede ser vacío.</span>}

                <ComboBox
                 allowFreeInput={true}
                 className="label_reusable"
                 placeholder="Seleccione la gerencia"
                 label="Gerencia"
                 options={gerenciaFilter}
                 styles={{root:{width:"250px"}}}
                 onChange={(_,option)=>onselectGerencia(option) } 
                 onInputValueChange={gerenciaFilterChange}
                 selectedKey={selectedOptionGerencia ? selectedOptionGerencia.key: null}
                 text={valueGerencia}
                 allowFreeform={valueEdingManagement === "1"? true:false}
                />
              { errors.gerencia?.type === "required" && <span className="error_TextFile_proyectofijo" >La gerencia no puede ser vacío.</span>}

                <ComboBox
                allowFreeInput={true}
                className="label_reusable"
                placeholder="Seleccione la jefatura"
                label="Jefatura"
                options={jefaturaFilter}
                styles={{root:{width:"250px"}}}
                onChange={(event,option)=>onselectJefatura(option)}
                onInputValueChange={filterFejaturaChange}
                selectedKey={selectedOptionJefatura ? selectedOptionJefatura.key: null}
                disabled={selecusuariosG? selecusuariosG.text === "Directivo":undefined}
                text={valueJefatura}
                allowFreeform={valueEdingManagement === "1"? true:false} 
                />
              { errors.jefatura?.type === "required" && <span className="error_TextFile_proyectofijo" >La jefatura no puede ser vacío.</span>}

                <ComboBox
                className="label_reusable"
                placeholder="Escribir el cargo"
                label="Cargo"
                options={cargofilter}
                styles={{root:{width:"170px"}}}
                onChange={(event,option)=>onChangeCargoo(event,option)}
                onInputValueChange={onInputValueChangeCargo}
                text={valueCargo}
                allowFreeform
                />
              { errors.cargo?.type === "required" && <span className="error_TextFile_proyectofijo" >El cargo no puede ser vacío.</span>}
                <div>
                        <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                          <span className="label_reusable">
                            <TooltipHost content={"Seleccione la fecha de ingreso"}>
                                <DatePicker  allowTextInput
                                    strings={spanishDatePickerStrings}
                                    formatDate={DateCustom.formatDateForDatePicker}
                                    placeholder="Seleccione la fecha de ingreso"
                                    label="Vigencia"
                                    styles={{root:{width:"170px"}}}
                                    minDate={minDateVigencia}
                                    onSelectDate={(date)=>handlerVigencia(date)}
                                    value={valueDateDesde?convertStringToDateProyectoEnalce(valueDateDesde):undefined}
                                    />
                            </TooltipHost>
                          </span> 
                            <p style={{marginTop:"30px"}}>hasta</p>
                          <span style={{marginTop:"27px" }} >
                            <TooltipHost content={"Seleccione la fecha de fin"}>
                                <DatePicker  allowTextInput
                                    strings={spanishDatePickerStrings}
                                    formatDate={DateCustom.formatDateForDatePicker}
                                    placeholder="Seleccione la fecha de fin"
                                    styles={{root:{width:"170px"}}}
                                    minDate={watch("carcol_fecha_desde")?convertStringToDateProyectoEnalce(watch("carcol_fecha_desde")):undefined}
                                    onSelectDate={(date)=>handlerFechaFin(date)}
                                    value={valueDateHasta?convertStringToDateProyectoEnalce(valueDateHasta):undefined}
                                    />
                            </TooltipHost>
                          </span>
                        </div>
                        { errors.carcol_fecha_desde?.type === "validate" && <span className="error_TextFile_proyectofijo" >Primero debes finalizar la estructura organizacional anterior.</span>}
                        { errors.carcol_fecha_desde?.type === "required" && <span className="error_TextFile_proyectofijo" >La de vigencia no puede ser vacío.</span>}
                </div>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center" }}>
               {
               modificarCrear? <PrimaryButton width="55" type="submit" >Agregar</PrimaryButton>: 
                        <div style={{display:'flex',gap:"20px"}}>
                              <PrimaryButton type="submit" style={{marginLeft:"10px", marginTop:"10px"}} >Modificar</PrimaryButton>
                              <PrimaryButton style={{ marginTop:"10px"}} onClick={()=>{
                              cleanDateTotal();
                              setModificarCrear(true)}}>cancelar</PrimaryButton> 
                        </div>
               }
                </div>
            </form>: <div style={{display:"flex",justifyContent:"center"}}><div className="loader_Proyect"></div></div>}
        </div>
        <ModalConfirm key={"eliminar"} message='¿Estás seguro de eliminar esta Estructura Organizacional?' isOpen={eliminar} onClose={()=>delteItem("onclose")} onConfirm={()=>delteItem("onConfirm")}
           />
        <ModalConfirm key={"desactivar"} message={valueTem?.Activo === "1"?'¿Estás seguro de desactivar esta Estructura Organizacional?':'¿Estás seguro de activar esta Estructura Organizacional?'}
         isOpen={desactivar} onClose={()=>Update("onclose")} onConfirm={()=>Update("onConfirm")} />
      </CustomModal>}
    </div>
  )
}

export default EstructuraOrganizacionalNEW;