import { Icon, Stack, Text } from '@fluentui/react';
import { useEffect, useState } from 'react';
import Environment from '../lib/Environment';

interface IofflineManagement{
  offline ?:string;
  unstableCOne ?:string;
  environment: Environment
}

function OfflineManagement({environment,offline="Computadora sin conexión",unstableCOne="Tu conexión es inestable."}:IofflineManagement) {
  const timelatency = environment.TimeLatency() || 5000;
  const timeInterval = environment.TimeInterval() || 10000;
  const [isConnectionUnstable, setIsConnectionUnstable] = useState(false);
  const [isOffline, setIsOffline] = useState(false);

  const handleReconnect = () => {
    window.location.reload();
  };

  const checkBackendLatency = async () => {
    const startTime = performance.now();

    try {
      const response = await fetch(window.location.href, { method: "HEAD" });

      if (!response.ok) {
        setIsConnectionUnstable(true);
        return;
      }

      const endTime = performance.now();
      const latency = endTime - startTime;

     // console.log(`Tiempo de respuesta del host: ${latency}ms`);

      if (latency > timelatency) {
        setIsConnectionUnstable(true);
      } else {
        setIsConnectionUnstable(false);
      }
    } catch (error) {
      setIsConnectionUnstable(true);
    }
  };

  useEffect(() => {

    const updateOnlineStatus = () => {
      setIsOffline(!navigator.onLine);
    };

    updateOnlineStatus();
    checkBackendLatency();

    if ("connection" in navigator) {
      const connection: any = navigator.connection;
      connection.addEventListener("change", checkBackendLatency);
    }
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    const interval = setInterval(checkBackendLatency, timeInterval);

    return () => {
      if ("connection" in navigator) {
        const connection: any = navigator.connection;
        connection.removeEventListener("change", checkBackendLatency);
      }
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
      clearInterval(interval);
    };
  }, []);


  return (
  <>
{ (isOffline || isConnectionUnstable) &&   <div>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        styles={{
          root: {
            backgroundColor: '#ffcccb',
            padding: '3px',
            flexWrap: 'nowrap',
          },
        }}
      >
        <Stack.Item>
          <Icon iconName="WifiWarning4" styles={{ root: { fontSize: 20, marginRight: 10 } }} />
        </Stack.Item>
        <Stack.Item grow>
          <Stack styles={{
            root: {
              display: 'flex',
              flexDirection: 'row',
              gridGap: '10px',
              '@media(max-width: 768px)': { 
                flexDirection: 'column',
                gridGap: '0px',
              },
            },
          }} >
            <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold',fontSize:"13px" } }}>
            {(isOffline && isConnectionUnstable) ? offline:unstableCOne}
            </Text>
            <Text variant="mediumPlus"  styles={{ root: { fontSize:"13px" } }}>{(isOffline && isConnectionUnstable) ? "Asegúrate de que tu computadora tenga conexión a internet activa.":""}</Text>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal verticalAlign="center" onClick={handleReconnect} style={{ cursor: 'pointer' }}>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: '#0078d4',
                  marginRight: '5px',
                  transition: 'color 0.3s ease',
                  ':hover': { color: '#005a9e' },
                },
              }}
            >
              Reconectar
            </Text>
            <Icon iconName="ChevronRightSmall" />
          </Stack>
        </Stack.Item>
      </Stack>
      <div
        style={{position: 'fixed',top: 0,left: 0,right: 0,bottom: 0,zIndex: 9999,
        pointerEvents: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        marginTop: window.innerWidth <= 768 ? "55px" : "30px"}}>
      </div>
    </div>}
    </>  
  );
}

export default OfflineManagement;