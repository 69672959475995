class Environment {
  ttsBaseApiUri() {
    return `${this.ttsBaseUri()}${this.ttsApiRelativeUri()}`;
  }

  apiUrl(path: string) {
    const base = this.ttsBaseApiUri();
    return `${base}${path}`;
  }

  ttsBaseUri() {
    return this.retrieveRequired('REACT_APP_TTS_BASE_URL');
  }

  captchaKey() {
    return this.retrieveRequired('REACT_APP_RECAPTCHA_KEY');
  }

  captchaAction() {
    return this.retrieveRequired('REACT_APP_RECAPTCHA_ACTION');
  }

  corporateSignInUrl() {
    return this.retrieveRequired('REACT_APP_TTS_CORPORATE_SIGN_IN_URL');
  }

  viewFromActivity(){
    return this.retrieveRequired('REACT_APP_TTS_VIEW_FORM_NEW_ACTIVITY');
  }

  isQaOrProuciont(){
    return this.retrieveRequired('REACT_APP_TTS_IS_QA');
  }

  EditingManagement(){
    return this.retrieveRequired('REACT_APP_TTS_NEW_DIRECCION')
  }

  TimeLatency():number{
    return parseInt(this.retrieveRequired('REACT_APP_LATENCY'));
  }

  TimeInterval():number{
    return parseInt(this.retrieveRequired('REACT_APP_INTERVAL')) ;
  }

  private ttsApiRelativeUri() {
    return this.retrieveRequired('REACT_APP_TTS_API_RELATIVE_URL');
  }

  private retrieveRequired(key: string) {
    const value = process.env[key];
    if (value === undefined) {
      throw new Error(`${key} is not defined`);
    }
    return value;
  }
}

export default Environment;
