import { useParams } from 'react-router-dom';
import MessageCourier from '../../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../../system';
import useAsyncDataCourier from '../../../common/custom-hooks/useAsyncDataCourier';
import { routes } from '../../../common/constants';

import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { t } from 'i18next';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import {
  DatePicker,
  DefaultButton,
  DefaultPalette,
  Dropdown,
  IDropdownOption,
  Icon,
  Pivot,
  PivotItem,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import {
  titleCommandBarButtonStyles,
  defaultCommandBarButtonStyles,
  buttonStylesForProjects,
} from '../../../styles/button-styles';
import { ProjectMiniPanelWrapper } from '../ProjectMiniPanel/ProjectMiniPanelWrapper';
import { User } from '../../../system/User';
import ProjectConfigurationDistributionForm from '../ProjectConfiguration/ProjectConfigurationDistributionForm';
import CustomInput from '../../../lib/FormValidator/Form/CustomInput';
import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import useForceUpdate from '../../../common/custom-hooks/useForceUpdate';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import { Company } from '../../../system/company/Company';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';
import { ProjectCompany } from '../../../system/projects/ProjectCompany';
import InputField from '../../../lib/FormValidator/Form/InputField';
import ComboBoxSearch from '../../Common/ComboBoxSearch';
import { DateCustom } from '../../../lib/DateCustom';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';
import { DateTime } from 'luxon';
import { ActivitiesToEditByPeriodTable } from './ActivitiesToEditByPeriodTable';
import { StepFinalInfo } from './StepFinalInfo';
import ErrorListForForm from '../../../lib/FormValidator/Form/ErrorListForForm';
import { useCustomInput } from '../../../common/custom-hooks/useCustomInput';
import { useNavigate } from 'react-router-dom';
import { IProjectDescriptor } from '../../../system/projects/ProjectDescriptor';
import PeriodDatesBlockedSystem from '../../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';
import { TooltipHost } from 'office-ui-fabric-react';
import useClipboardExcel from '../../../common/custom-hooks/useClipboardExcel';
import ToastErrorAndExit from '../../Collaborator/CollaboratorView/updateCollaborator/toastErrorAndExit';

interface IProjectDistributionByPeriodWizard {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (b: boolean) => void;
  user: User;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export const ProjectDistributionByPeriodWizard = (props: IProjectDistributionByPeriodWizard) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [verActividades, setVerActividades] = React.useState<boolean>(false);
  const [paso, setPaso] = React.useState<number>(1);
  const [fromDate, setFromDate] = React.useState<DateTime>(
      DateTime.fromJSDate(DateCustom.getFirstDayOfThisMonth())
  );
  const [toDate, setToDate] = React.useState<DateTime>(DateTime.fromJSDate(new Date()));
  const { isActive, handleCloseActive, copyToClipboard, pasteFromClipboard } = useClipboardExcel();
  const [NotCompanies, setNotCompanies] = useState<any[]>();
  const onChangeDate = (date: Date | null | undefined, name: string) => {
    if (date) {
      if (name === 'from') {
        setFromDate(DateTime.fromJSDate(date));
      }
      if (name === 'to') {
        setToDate(DateTime.fromJSDate(date));
      }
    }
  };

  const projects = useAsyncDataCourier(() => props.system.getProjects(), [props.system], props.courier);

  const project = useAsyncDataCourier(
      () => (id ? props.system.getProjectSystem().getById(id) : Promise.resolve(undefined)),
      [props.system, id],
      props.courier,
      props.changeBlockUI
  );

  const contextForm = useAsyncDataCourier(
      () => props.system.getProjectSystem().getContext(),
      [props.system],
      props.courier
  );
  const actividadesAEditar = useAsyncDataCourier(
      () =>
          project && verActividades
              ? props.system.getProjectSystem().getActivitiesToEditByPeriod(project.getId(), fromDate, toDate)
              : Promise.resolve([]),
      [props.system, project, fromDate, toDate, verActividades],
      props.courier,
      props.changeBlockUI
  );

  const qtyActividadesAEditar = useAsyncDataCourier(
      () =>
          project
              ? props.system.getProjectSystem().getQtyActivitiesToEditByPeriod(project.getId(), fromDate, toDate)
              : Promise.resolve(0),
      [props.system, project, fromDate, toDate],

      props.courier,
      props.changeBlockUI
  );

  const distributionForm = useCustomInput(() => {
    return ProjectConfigurationDistributionForm.with(
        [],
        new CustomInput('billable_mock', project?.getBillable(), new AssertionEntity())
    );
  }, [project]);

  const distributionItems = useCustomInput(
      () => distributionForm.getInputDistribucionFacturableItems(),
      [distributionForm],
      ['setValue', 'add', 'delete']
  );

  const onClickProject = (project: IProjectDescriptor) => () => {
    navigate(`${routes.edit_distribution_by_period}/${project.getId()}`);
  };

  const itemsMenu = React.useMemo((): ICommandBarItemProps[] => {
    let ret: ICommandBarItemProps[] = [];
    ret.push(
        {
          key: 'project',
          text: project?.getCode(),
          itemProps: {},
          iconProps: { iconName: 'Teamwork', color: DefaultPalette.themeLighterAlt },
          subMenuProps: {
            items: projects
                ? projects.map((project) => ({
                  key: project.getCode(),
                  text: project.getCode(),
                  onClick: onClickProject(project),
                }))
                : [],
          },
          buttonStyles: buttonStylesForProjects,
        },
        {
          key: 'title',
          text: t('Edición de distribución facturable de actividades por período'),
          iconProps: {
            iconName: 'TeamWork',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'download',
          text: t('Config'),
          onClick: () => navigate(`${routes.project_config}/${project?.getId()}`),
          iconProps: { iconName: 'Settings' },
          buttonStyles: defaultCommandBarButtonStyles,
        }
    );

    return ret;
  }, [project, projects]);

  const [companyOptionsFiltered, setCompanyOptionsFiltered] = React.useState<Company[]>([]);
  const [typesList, setTypesList] = React.useState<[]>([]);
  const forceUpdate = useForceUpdate();

  const companyOptions = React.useMemo(() => {
    return contextForm?.companyList;
  }, [contextForm]);

  const getTotalPercent = () => {
    let percent: number = 0;
    if (distributionItems.getValue() && distributionForm)
      distributionItems.getValue().map((projectCompany, i) => {
        if (
            projectCompany &&
            distributionItems?.getFormFor(projectCompany)?.getPercentageDistributionInput()?.getValue()
        ) {
          return (percent += parseFloat(
              distributionItems.getFormFor(projectCompany).getPercentageDistributionInput().getValue()
          ));
        }
      });
    percent = parseFloat(percent.toFixed(2));
    if (percent >= 99.99 && percent < 100) {
      percent = 100;
    }

    return percent;
  };

  useEffect(() => {
    getTotalPercent();
  });
  const setCompanyListOutsideProject = (val?: string) => {
    const ret = companyOptions?.filter((com) => {
      const companyIsInProject = distributionItems.hasCompany(com);

      const sameBillableType =
          project?.getBillable() === com.getAllow_billable_project() ||
          !project?.getBillable() === com.getAllow_no_billable_project();

      return !companyIsInProject && com.tradeNameLike(val) && sameBillableType;
    });
    if (ret) setCompanyOptionsFiltered(ret);
  };

  React.useEffect(() => {
    setCompanyListOutsideProject();
  }, [project?.getProjectCompanyList().getCompanies(), contextForm, id]);

  const onChangeCompany = () => {
    // setProjectDistribution(
    //   distributionForm ? distributionForm.getInputDistribucionFacturableItems().getActive() : []
    // );
    distributionForm?.evaluate();
    setCompanyListOutsideProject();
    // forceUpdate();
  };

  const onClickAssignationUniform = () => {
    distributionForm?.uniformAssignment();
    distributionForm?.evaluate();
    forceUpdate();
  };

  const onDeleteItem = (company: ProjectCompanyInDistribution) => {
    distributionItems.delete(company);
    onChangeCompany();
  };

  const onBlurPercentage = (customInput: CustomInputIfc, projectCompany: ProjectCompanyInDistribution) => {
    forceUpdate();
    distributionItems.syncWithForm(projectCompany);
  };

  const onSelectCompany = (companyId: string) => {
    const com = companyOptionsFiltered.find((item) => item.isIdentifiedBy(companyId));
    distributionItems.add(ProjectCompany.with(0, com!));
    distributionForm?.evaluate();
    distributionItems?.evaluate();
    onChangeCompany();
  };

  const onSelectCompanyType = (type: string) => {
    let allCompanyOptions: Company[] = companyOptionsFiltered;
    distributionItems.getValue().forEach((project) => {
      allCompanyOptions.forEach((company, index) => {
        if (project.getCompany().getId() === company.getId()) {
          allCompanyOptions.splice(index, 1);
        }
      });
    });
    allCompanyOptions.forEach((company, index) => {
      if (company.getType() === type) {
        onSelectCompany(company.getId());
      }
    });
  };

  const dropdownOptions: IDropdownOption[] = React.useMemo(
      () =>
          companyOptionsFiltered.reduce((options: IDropdownOption[], comOption: Company) => {
            const optionExists = options.some(
                (option) => option.key === comOption.getType() && comOption.getType()
            );
            if (!optionExists && !!comOption.getType()) {
              const newOption: IDropdownOption = {
                key: comOption.getType(),
                text: t(comOption.getType()),
              };
              options.push(newOption);
            }
            return options;
          }, []),
      [companyOptionsFiltered]
  );

  const next = () => {
    if (paso < 3) setPaso(paso + 1);
  };
  const back = () => {
    if (paso > 0) setPaso(paso - 1);
  };

  const applyDistribution = async () => {
    if (distributionForm && project) {
      const distributionList = distributionItems
          .getValue()
          .filter((e) => e.isActive())
          .map((e) => {
            const ret: { porcentaje: number; empresaId: string } = {
              porcentaje: e.getPercentage(),
              empresaId: e.getCompany().getId(),
            };
            return ret;
          });

      try {
        props.changeBlockUI(true);
        await props.system.getProjectSystem().applyDisttributionInPeriod({
          distributionList: distributionList,
          desde: fromDate.toFormat('yyyy-LL-dd'),
          hasta: toDate.toFormat('yyyy-LL-dd'),
          proyectoId: project?.getId(),
        });
        props.courier.messageSuccess('Se han aplicado los cambios con éxito.');
        navigate(`${routes.project_config}/${project?.getId()}`);
      } catch (e) {
        props.courier.messageError(e);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const dataToExcel = () => {
    const dataExcel: any[][] = [];

    distributionItems.getValue().forEach((projectCompany) => {
      const companyName = projectCompany.getCompany().getName();
      const percentageDistribution: any = distributionItems
        .getFormFor(projectCompany)
        .getPercentageDistributionInput()
      dataExcel.push([companyName, percentageDistribution.propertyValue]);
    })

    return dataExcel;
  };

  const dataFromExcel = async () => {
    onSelectCompanyType('Vinculada');
    await pasteFromClipboard((processedData) => {
      updateDistribution(processedData);
      listcompaniesIsNotRegister(processedData);
    });
  };

  const updateDistribution = (data:any[]) => {
    distributionItems.updatePercentage(data)
    distributionForm?.evaluate();
    forceUpdate();
  };

  const listcompaniesIsNotRegister = (data:any[]) => {
    setNotCompanies(distributionItems.companiesIsNotRegister(data));
  }

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody="Proyectos"
      >
        <div>
          {project && contextForm && (
              <div className="mb-2">
                <div className="ms-Grid" dir="ltr">
                  <ProjectMiniPanelWrapper
                      selectedProject={project}
                      system={props.system}
                      courier={props.courier}
                      setViewControlDocsWrapper={() => {}}
                      user={props.user}
                      actualizateProject={() => {}}
                      changeBlockUI={props.changeBlockUI}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 mt-2">
                  <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
                    <Pivot selectedKey={String(paso)}>
                      <PivotItem headerText="1. Edición de distribución" itemKey={'1'}>
                        <div className="ms-Grid-col ms-sm12 ">
                          <div className="ms-Grid-col ms-sm12 mb-2 mt-2">
                            <Text variant="mediumPlus" className="d-block">
                              <Icon className="text-icon" iconName="Info"></Icon>Aquí configura la distribución
                              que quieres aplicar a las actividades.
                            </Text>
                            {/* <Text variant="mediumPlus" className="d-block mt-1 d-block">
                          <Icon className="text-icon error-color blinking-icon" iconName="AlertSolid"></Icon>
                          <span style={{ fontWeight: 600 }}>¡CUIDADO!</span> Si lo que quieres es editar
                          actividades especificas esta <span style={{ fontWeight: 600 }}>NO</span> es la
                          herramienta recomendada, en cambio te recomendamos que lo hagas{' '}
                          <span
                            className="link-styles"
                            onClick={() => {
                              navigate(`${routes.distribution_corrector}?proId=${project.getId()}`);
                            }}
                          >
                            aquí
                          </span>
                        </Text> */}
                          </div>
                          <div className="ms-Grid-col ms-sm6 mt-2">
                            {isActive && (
                              <div className='mt-2 mb-2'>
                                <ToastErrorAndExit
                                  message="Datos copiados al portapapeles. Pegalo en un Excel."
                                  tipe="exit"
                                  closeToast={handleCloseActive}
                                />
                              </div>
                            )}
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm4">
                                <Icon iconName="CityNext2" className="text-icon" />
                                <Text variant={'large'} className={'project-config-section-title'}>
                                  {t('Empresas usuarias')}
                                </Text>
                              </div>
                              <div className="ms-Grid-col ms-sm3">
                                {companyOptionsFiltered && (
                                    <Dropdown
                                        placeholder={t('Agregar compañías del tipo')}
                                        options={dropdownOptions}
                                        selectedKey={''} 
                                        onChange={(_event, option) => {
                                          if (option) {
                                            onSelectCompanyType(String(option.key));
                                          }
                                        }}
                                    ></Dropdown>
                                )}
                              </div>
                              <div className="ms-Grid-col ms-sm3">
                                <DefaultButton
                                    onClick={onClickAssignationUniform}
                                    className="float-right"
                                    text={t('Asignación uniforme')}
                                />
                              </div>
                              <div className="ms-Grid-col ms-sm-2"
                                style={{
                                  justifyContent: "flex-end",
                                  display: "flex",
                                  marginRight: 1,
                                }}
                              >
                                <TooltipHost content="Copiar como Excel">
                                  <DefaultButton
                                    iconProps={{ iconName: "Copy", styles: {
                                      root: {
                                        fontSize: "20px",
                                      },
                                    } }}
                                    ariaLabel="Copiar"
                                    styles={{
                                      root: {
                                        minWidth: "32px",
                                        padding: 0,
                                        border: "none",
                                      },
                                    }}
                                    onClick={() => copyToClipboard(dataToExcel())}
                                  />
                                </TooltipHost>
                                <TooltipHost content="Pegar desde Excel">
                                  <DefaultButton
                                    iconProps={{ iconName: "Paste",styles: {
                                      root: {
                                        fontSize: "20px",
                                      },
                                    }}}
                                    ariaLabel="Pegar"
                                    styles={{
                                      root: {
                                        minWidth: "32px",
                                        padding: 0,
                                        border: "none",
                                      },
                                    }}
                                    onClick={dataFromExcel}
                                  />
                                </TooltipHost>
                            </div>
                            </div>
                            <div className="ms-Grid-row mt-2">
                              <div className="ms-Grid-col ms-sm12">
                                {distributionForm && !distributionForm?.isValid() && (
                                    <ErrorListForForm customForm={distributionForm} />
                                )}
                              </div>
                            </div>
                            <div className="ms-Grid-row mt-2">
                              <div className="ms-Grid-col ms-sm12 ">
                                {companyOptionsFiltered && (
                                    <ComboBoxSearch
                                        options={companyOptionsFiltered.map((comOption: Company) => {
                                          let ret: IDropdownOption = {
                                            key: comOption.getId(),
                                            text: comOption.getName(),
                                          };
                                          return ret;
                                        })}
                                        selected={['-1']}
                                        onSelectOption={(option) => {
                                          if (option) {
                                            onSelectCompany(String(option.key));
                                          }
                                        }}
                                        cleanOnSelect={true}
                                    ></ComboBoxSearch>
                                )}
                              {
                              NotCompanies?.map((company,i) => {
                                return (
                                  <div key={i} className="ms-Grid-row mt-1">
                                    <div className="ms-Grid-col ms-sm8">
                                      <Text
                                        styles={{
                                          root: {
                                            color: "red",
                                          },
                                        }}
                                        variant="medium"
                                      >
                                        <Icon iconName="EMI" className="text-icon" />
                                        {company[0]}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        color: "red",
                                      }}
                                      className="ms-Grid-col ms-sm4 "
                                    >
                                      <span>Empresa no existente eliminada.</span>
                                    </div>
                                  </div>
                                );
                              })
                              }
                              </div>
                            </div>
                            {distributionForm &&
                                distributionItems.getValue().map((projectCompany, i) => {
                                  return (
                                      <div key={i} className="ms-Grid-row mt-1">
                                        <div className="ms-Grid-col ms-sm8 ">
                                          <Text variant="medium">
                                            <Icon iconName="EMI" className="text-icon" />
                                            {projectCompany.getCompany().getName()}
                                          </Text>
                                        </div>

                                        {distributionItems.getFormFor(projectCompany) && (
                                            <div className="ms-Grid-col ms-sm3 ">
                                              <InputField
                                                  input={distributionItems
                                                      .getFormFor(projectCompany)
                                                      .getPercentageDistributionInput()}
                                                  label=""
                                                  onBlur={(ci) => onBlurPercentage(ci, projectCompany)}
                                                  propsInput={{
                                                    placeholder: 'Asig.',
                                                    suffix: '%',
                                                    maxLength: 5,
                                                    title: 'Porcentaje de asignación',
                                                  }}
                                              />
                                            </div>
                                        )}

                                        <div className="ms-Grid-col ms-sm1 mt-1">
                                          <Icon
                                              iconName="Trash"
                                              className="cursor-pointer"
                                              title="Eliminar del equipo"
                                              onClick={() => onDeleteItem(projectCompany)}
                                          />
                                        </div>
                                      </div>
                                  );
                                  // }
                                })}
                            <div className="ms-Grid-row mt-1">
                              <div className="ms-Grid-col ms-sm6 "></div>
                              <div className="ms-Grid-col ms-sm6 ">
                                <div
                                    // className={getTotalPercent() > 100 ? 'error-text-red' : ''}
                                    style={{ textAlign: 'right', marginRight: '50px', paddingTop: '10px' }}
                                >
                                  {t('Asignación total: ') + getTotalPercent() + '%'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </PivotItem>
                      <PivotItem headerText="2. Periodo aplicable" itemKey={'2'}>
                        <div className="ms-Grid-col ms-sm12 ">
                          <div className="ms-Grid-col ms-sm12 mb-2 mt-2">
                            <Text variant="mediumPlus">
                              <Icon className="text-icon" iconName="Info"></Icon>En el periodo seleccionado se
                              buscarán todas las actividades pertenecientes a este proyecto y se aplicará la
                              distribución configurada en el paso anterior.
                            </Text>
                          </div>

                          <div className="ms-Grid-col ms-sm3 ">
                            <DatePicker
                                strings={getLocalizedStrings()}
                                label={t('Aplicar desde')}
                                formatDate={DateCustom.formatDate}
                                value={fromDate ? fromDate.toJSDate() : undefined}
                                onSelectDate={(e) => onChangeDate(e, 'from')}
                                className=""
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm3 ">
                            <DatePicker
                                strings={getLocalizedStrings()}
                                label={t('Aplicar hasta')}
                                formatDate={DateCustom.formatDate}
                                value={toDate ? toDate.toJSDate() : undefined}
                                onSelectDate={(e) => onChangeDate(e, 'to')}
                                className=""
                            />
                          </div>
                        </div>
                      </PivotItem>
                      <PivotItem headerText="3. Aplicar nueva distribución" itemKey={'3'}>
                        <StepFinalInfo
                            qtyActividadesAEditar={qtyActividadesAEditar}
                            project={project}
                        ></StepFinalInfo>
                      </PivotItem>
                    </Pivot>
                    <div className="ms-Grid-col ms-sm12 mt-2 mb-2">
                      {paso === 3 && (
                          <DefaultButton onClick={() => setVerActividades(!verActividades)}>
                            {verActividades
                                ? 'Ocultar actividades que se editarán'
                                : 'Ver actividades que se editarán'}
                          </DefaultButton>
                      )}
                      {paso !== 3 ? (
                          <DefaultButton
                              style={{ float: 'right' }}
                              onClick={next}
                              disabled={paso === 3 || !distributionForm?.isValid()}
                          >
                            Siguiente
                          </DefaultButton>
                      ) : (
                          <PrimaryButton
                              disabled={qtyActividadesAEditar === 0}
                              style={{ float: 'right', fontWeight: 600 }}
                              onClick={applyDistribution}
                              iconProps={{ iconName: 'SaveAs' }}
                          >
                            Aplicar distribución a {qtyActividadesAEditar} actividades
                          </PrimaryButton>
                      )}

                      <DefaultButton
                          style={{ float: 'right', marginRight: 10 }}
                          onClick={back}
                          disabled={paso === 1}
                      >
                        Anterior
                      </DefaultButton>
                    </div>
                  </div>
                  {paso === 3 && verActividades && actividadesAEditar && actividadesAEditar.length > 0 && (
                      <ActivitiesToEditByPeriodTable
                          system={props.system}
                          actividadesAEditar={actividadesAEditar}
                          courier={props.courier}
                          user={props.user}
                          changeBlockUI={props.changeBlockUI}
                          config={props.config}
                          periodsBlocked={props.periodsBlocked}
                          hideEdit
                          projectId={project.getId()}
                          projects={projects}
                      ></ActivitiesToEditByPeriodTable>
                  )}
                </div>
              </div>
          )}
        </div>
      </ViewWrapperWithCommandBar>
  );
};
