// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import { ComboBox, Stack, IComboBoxOption, IStackTokens, IComboBoxStyles, IComboBox, Text } from '@fluentui/react';
import { OrganizationChartNode } from '../../system/organization_chart/OrganizationChartNode';
import './OrganizationChartDropdownStyles.css';
import { useTranslation } from 'react-i18next';
import CustomInputIfc from '../../lib/FormValidator/Form/CustomInputIfc';
import ErrorListForInput from '../../lib/FormValidator/Form/ErrorListForInput';

// Optional styling to make the example look nicer
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 700 } };
const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };

interface IOrganizationChartDropdownProps {
  options: OrganizationChartNode[];
  className?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (option: OrganizationChartNode) => void;
  onSelectAll?: () => void;
  selectedKey?: string | undefined;
  // hasError?: string | undefined;
  hasError?: CustomInputIfc | undefined;
  defaultSelectedKey?: string | undefined;
  useAllSector?: boolean;
  autoComplete?: 'on' | 'off' | undefined;
  value?: string | undefined;
}

const useFocus = () => {
  const htmlElRef = React.useRef<IComboBox>(null);
  const setFocus = () => {
    if (htmlElRef?.current) htmlElRef?.current.focus(true);
  };

  return [htmlElRef, setFocus];
};

export const ALL_SECTORS = 'All';
export const OrganizationChartDropdown = (props: IOrganizationChartDropdownProps) => {
  const { t } = useTranslation();
  const [allowFreeform, setAllowFreeForm] = useState<boolean>(false);
  const [autoComplete, setAutoComplete] = useState<'on' | 'off' | undefined>('on');
  const [filteredNodes, setFilteredNodes] = useState<IComboBoxOption[]>();
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(props.selectedKey);
  const [searchText, setSearchText] = useState<string>();
  const [inputRef, setInputFocus] = useFocus();

  const formattedNodes = React.useMemo(() => {
    const ret = props.options.map((node): IComboBoxOption => {
      return {
        key: node.getId(),
        text: node.parentPath() ? node.getName() + ' (' + node.parentPath() + ')' : node.getName(),
        title: node.getName(),
        ariaLabel: node.parentPath(),
        id: node.getLevel().getName(),
      };
    });

    if (props.useAllSector) {
      ret.unshift({
        key: ALL_SECTORS,
        text: t('Todos'),
        title: t('Todos'),
      });
    }

    return ret;
  }, [props.options, props.useAllSector, t]);

  useEffect(() => {
    setSelectedKey(props.selectedKey);
  }, [props.selectedKey]);

  useEffect(() => {
    setFilteredNodes(formattedNodes);
  }, [formattedNodes, props.options, props]);

  const getFilteredNodes = React.useCallback(() => {
    let _options: IComboBoxOption[] = [];
    formattedNodes.forEach((node: any) => {
      if (node.title && node.title.toUpperCase().includes(searchText ? searchText.toUpperCase() : searchText))
        _options.push(node);
    });
    setFilteredNodes(_options);
  }, [formattedNodes, searchText]);

  useEffect(() => {
    getFilteredNodes();
  }, [getFilteredNodes]);

  const onRenderOption = (item: any) => {
    switch (item.itemType) {
      default:
        return (
            <div
                className="organizationChartContainer"
                id="organizationChartContainer"
                style={{ marginBottom: '5px' }}
            >
              <div style={{ marginBottom: '-8px' }}>
                <span style={{ fontWeight: '600' }}>{item.title}</span>{' '}
                {item.id ? (
                    <span className="organizationChartSpan" style={{ fontSize: '10px' }}>
                  {item.id}
                </span>
                ) : null}
              </div>

              {item.ariaLabel ? (
                  <span className="organizationChartDropdownPath" style={{ fontSize: '10px', marginTop: '-8px' }}>
                {item.ariaLabel}
              </span>
              ) : null}
            </div>
        );
    }
  };

  useEffect(() => {
    if (searchText && searchText?.length > 0) {
      setAllowFreeForm(true);
      // setAutoComplete('off');
      // @ts-ignore
      setInputFocus();
    } else if (!selectedKey) {
      setAllowFreeForm(false);
      setAutoComplete('on');
    }
  }, [searchText, inputRef]);

  return (
    <>
      <Stack horizontal tokens={stackTokens} className="organizationChartStack">
        {filteredNodes && (
          <ComboBox
            componentRef={inputRef}
            defaultSelectedKey={props.defaultSelectedKey}
            className={props.className}
            label={props.label ? t(props.label) : ""}
            allowFreeform={allowFreeform}
            autoComplete={autoComplete}
            options={filteredNodes.length > 0 ? filteredNodes : formattedNodes}
            styles={comboBoxStyles}
            onChange={(
              event: React.FormEvent<IComboBox>,
              option?: IComboBoxOption | undefined,
              index?: number | undefined,
              value?: string | undefined
            ) => {
              if (option) {
                const optionSelected = String(option!.key);
                const nodeSelected = props.options!.find((node) =>
                  node.isIdentifiedBy(optionSelected)
                );
                if (nodeSelected && props.onChange)
                  props.onChange(nodeSelected);
                if (optionSelected === ALL_SECTORS && props.onSelectAll)
                  props.onSelectAll();

                setSelectedKey(optionSelected);
              }
            }}
            selectedKey={props.value === "" ? "" : selectedKey}
            onInputValueChange={(text) => {
              setSearchText(text);
              // setAllowFreeForm(true);
              // setAutoComplete('off');
            }}
            disabled={props.disabled ? true : false}
            onRenderOption={onRenderOption}
            dropdownMaxWidth={600}
            onItemClick={(e) => {
              // console.log(e)
            }}
            persistMenu
            // onMenuDismissed={() => setSearchText(undefined)}
            // Force re-creating the component when the toggles change (for demo purposes)
            // key={'' + 'on' + true}
          />
        )}
      </Stack>
      {props.hasError && <ErrorListForInput customInput={props.hasError} />}
    </>
  );
};
